import React from "react";
import "./BlogStyles.css";

const StepByStepGuideToPerformUmrah = () => {
  return (
    <div>
      <div className="readDetailsImgContainer">
        <img
          src="https://i.ibb.co/jZ1ksPM/Zam-Zam-blog-image.png"
          alt="zamzam-step-by-step-guide"
        />
        <div className="ArticleTitle">
          How to Perform Umrah? Step by Step Guide
        </div>
        <div className="paraSection">
          <p>
            Umrah, a spiritual journey to the holy cities of Makkah and Madinah,
            holds profound significance in Islam. Although it is not obligatory
            like Hajj, it offers Muslims a chance to purify their hearts and
            strengthen their relationship with Allah. This blog will explain
            each step of the Umrah ritual to guide those embarking on this
            blessed journey.
          </p>
        </div>
        <div className="paraSection">
          <h3>1. What is Umrah?</h3>
        </div>
        <div className="paraSection">
          <p>
            Umrah is a pilgrimage performed by Muslims that involves several
            rituals. Unlike Hajj, it can be undertaken at any time of the year.
            It consists of fewer rituals than Hajj, but it holds immense
            spiritual value, providing an opportunity for atonement and
            closeness to Allah.
          </p>
        </div>
        <div className="paraSection">
          <h3>2. Preparing for Umrah: Entering Ihram</h3>
        </div>
        <div className="paraSection">
          <p>
            Before reaching the holy sanctuary, pilgrims must enter a sacred
            state called Ihram.
          </p>
          <ul>
            <li>
              <b>Men</b> wear two white, unstitched clothes, while women wear
              modest clothing.
            </li>
            <li>
              Upon entering Ihram, pilgrims recite the Talbiyah ("Here I am, O
              Allah, here I am") and are bound by certain restrictions like
              refraining from cutting nails, trimming hair, or using perfumes.
            </li>
          </ul>
        </div>
        <div className="paraSection">
          <h3>3. Tawaf: Circumambulating the Kaaba</h3>
        </div>
        <div className="paraSection">
          <p>
            Once in the sacred precinct of Makkah, the first ritual is{" "}
            <b>Tawaf</b>.
          </p>
          <ul>
            <li>
              Pilgrims circumambulate the <b>Kaaba</b> seven times in a
              counterclockwise direction, showing unity and devotion to Allah.
            </li>
            <li>
              During Tawaf, prayers are recited, and pilgrims may touch or kiss
              the Black Stone <b>(Hajr al-Aswad)</b> if possible, though it's
              not obligatory.
            </li>
          </ul>
        </div>
        <div className="paraSection">
          <h3>4. Sa’i: Walking Between Safa and Marwah</h3>
        </div>
        <div className="paraSection">
          <p>
            After completing Tawaf, the next ritual is Sa’i, which commemorates
            Hagar's search for water for her son, Ismail.
          </p>
          <ul>
            <li>
              Pilgrims walk briskly between the hills of <b>Safa</b> and{" "}
              <b>Marwah</b> seven times.
            </li>
            <li>
              This act symbolizes patience, faith, and the struggle for Allah's
              blessings.
            </li>
          </ul>
        </div>
        <div className="paraSection">
          <h3>5. Haircutting or Shaving: Ending Ihram</h3>
        </div>
        <div className="paraSection">
          <p>
            The final step of Umrah is to cut or shave the hair, marking the
            completion of the rituals.
          </p>
          <ul>
            <li>
              <b>Men</b> can either shave their heads or trim their hair, while
              women cut a small portion of their hair.
            </li>
            <li>
              After this act, pilgrims leave the state of Ihram, and the
              restrictions are lifted.
            </li>
          </ul>
        </div>
        <div className="paraSection">
          <h3>6. Visiting Madinah (Optional but Recommended)</h3>
        </div>
        <div className="paraSection">
          <p>
            Though not a part of Umrah, many pilgrims visit <b>Madinah</b> to
            pray at the <b>Prophet’s Mosque (Masjid al-Nabawi)</b>. Paying
            respects at the
            <b>Prophet Muhammad's (PBUH)</b> tomb and offering prayers at
            <b>Rawdah</b>, a revered area inside the mosque, is an enriching
            spiritual experience.
          </p>
        </div>
        <div className="paraSection">
          <h3>Conclusion</h3>
        </div>
        <div className="paraSection">
          <p>
            Umrah is a journey of the heart, soul, and body, offering believers
            a unique chance to renew their faith. By understanding the
            significance of each ritual, one can fully embrace the spiritual
            essence of this sacred pilgrimage. May Allah accept the efforts of
            all who seek His pleasure through Umrah.
          </p>
        </div>
        <div className="paraSection">
          <h3>Book Your Umrah with ZamZam Hajj & Umrah</h3>
        </div>
        <div className="paraSection">
          <p>
            Embark on a blessed Umrah journey with ZamZam Hajj & Umrah USA! With
            13+ years of experience, we’ve guided hundreds of satisfied
            pilgrims. Our dedicated team, based in Saudi Arabia and Egypt,
            ensures full support throughout your pilgrimage.
          </p>
        </div>
        <div className="paraSection">
          <b>
            {" "}
            <a href=" https://www.zamzamhajj.com/umrah-2025">
              Book your journey today.{" "}
            </a>
          </b>
        </div>
      </div>
    </div>
  );
};

export default StepByStepGuideToPerformUmrah;
