import React from "react";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import SectionTitle from "../Common/SectionTitle";
import PageTitle from "../Common/PageTitle";
import { Grid } from "@material-ui/core";
import CardTwo from "../Common/CardTwo/CardTwo";

const cardTwoData = [
  {
    name: "Hassan Akber",
    date: "JAN 01, 2025",
    img: "https://i.ibb.co/jZ1ksPM/Zam-Zam-blog-image.png",
    title: "How to Perform Umrah? Step by Step Guide",
    Discription:
      "Umrah, a spiritual journey to the holy cities of Makkah and Madinah, holds profound significance in Islam. Although it is not ...",
    link: "reads/step-by-step-perform-guide-for-umrah",
  },
  {
    name: "Hassan Akber",
    date: "MAY 05, 2020",
    img: "https://zamzamhajj-media.s3.amazonaws.com/zamzam-blogs/Zamzam-StepByStepGuideForUmrah-blogcover-card-image.png",
    title: "Step-by-Step Guide to Preparing for Umrah",
    Discription:
      "Umrah is a special pilgrimage to the holy city of Mecca, which Muslims can perform at any time of the year. It holds immense spiritual ...",
    link: "reads/guide-for-umrah",
  },
];

const Reads = () => {
  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContainer">
        <div>
          <PageTitle Title="READS/VIEWS" />

          {/* <HadithSection /> */}
        </div>
        {/* <SectionTitle
          TitleLight="LATEST"
          TitleBold="READS/VIEWS"
          ButtonStatus={false}
        /> */}
        <Grid container spacing={3} style={{ padding: "24px 14px" }}>
          <Grid item md={12} sm={12} xs={12}>
            <div className="readsCardTOp">
              <CardTwo
                cardTwoData={cardTwoData}
                col="12"
                Imgcol="6"
                Contentcol="6"
                spacing={3}
                buttonLabel="READ MORE"
                hoverbackground="#28b356"
                titleColor="#28b356"
              />
            </div>
          </Grid>

          {/* <Grid item md={12} sm={12} xs={12}>
            <div className="readsCardTOp">
              <CardTwo
                cardTwoData={cardTwoData}
                col="6"
                Imgcol="12"
                Contentcol="12"
                spacing={3}
                buttonLabel="READ MORE"
                hoverbackground="#0052a5"
                titleColor="#0053a5"
              />
            </div>
          </Grid> */}
        </Grid>
      </div>
      <FooterSection />
    </div>
  );
};

export default Reads;
